import {config, loadApi} from "@systempiloten/react-lib";
import "material-symbols/outlined.css";
import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import "@systempiloten/react-lib/dist/styles.css";
import "./index.css";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
(async () => {
    await fetch("/configuration.json")
        .then((res) => res.json())
        .then((data) => config.load(data))
        .catch((e) => null);
    await loadApi().catch(() => void 0);
    const {default: App} = await import("./App");
    root.render(<App/>);
})();
